import { shouldPolyfill } from '@formatjs/intl-datetimeformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillDisplayNames } from '@formatjs/intl-displaynames/should-polyfill'

export async function polyfillIcelandicDateTimeFormat() {
  if (shouldPolyfill('is')) {
    await import('@formatjs/intl-datetimeformat/polyfill-force')
    await import(`@formatjs/intl-datetimeformat/locale-data/is`)
  }

  if (shouldPolyfillDisplayNames('is')) {
    await import('@formatjs/intl-displaynames/polyfill-force')
    await import(`@formatjs/intl-displaynames/locale-data/is`)
  }
}
