import 'vite/modulepreload-polyfill'
import { ru, uk, is, pl, lt, lv, enUS as en, es } from 'date-fns/locale'
import moment from 'moment'
import 'moment-timezone'
import momentDurationFormatSetup from 'moment-duration-format'
import 'bootstrap'

global.moment = moment

import './@decorators/select2@adapters'
import { setDefaultOptions } from 'date-fns'

import { polyfillIcelandicDateTimeFormat } from './polyfills'

const locales = { en, lv, lt, pl, is, uk, ru, es }
if (Translator.locale === 'is') {
  polyfillIcelandicDateTimeFormat()
}

setDefaultOptions({ locale: locales[Translator.locale] })

momentDurationFormatSetup(moment)
