$.fn.select2.amd.define(
  'MultipleCustomSelectionAdapter',
  [
    'select2/utils',
    'select2/selection/multiple',
    'select2/selection/placeholder',
    'select2/selection/eventRelay',
    'select2/selection/single',
  ],
  function (Utils, MultipleSelection, Placeholder, EventRelay, SingleSelection) {
    // Decorates MultipleSelection with Placeholder
    let adapter = Utils.Decorate(MultipleSelection, Placeholder)
    // Decorates adapter with EventRelay - ensures events will continue to fire
    // e.g. selected, changed
    adapter = Utils.Decorate(adapter, EventRelay)

    adapter.prototype.render = function () {
      // Use selection-box from SingleSelection adapter
      // This implementation overrides the default implementation
      const $selection = SingleSelection.prototype.render.call(this)
      return $selection
    }

    adapter.prototype.update = function (data) {
      // copy and modify SingleSelection adapter
      this.clear()

      const $rendered = this.$selection.find('.select2-selection__rendered')
      const noItemsSelected = data.length === 0
      let formatted = ''

      if (noItemsSelected) {
        formatted = this.options.get('placeholder') || ''
      } else {
        const itemsData = {
          selected: data || [],
          all: this.$element.find('option') || [],
        }
        // Pass selected and all items to display method
        // which calls templateSelection
        formatted = this.display({ ...itemsData, placeholder: this.options.get('placeholder') }, $rendered)
      }

      $rendered.empty().append(formatted)
      $rendered.closest('.form-group').removeClass('has-selection')
      $rendered.closest('.form-group').find('.filter-item-icon').find('.selected-amount').remove()
      if (data.length) {
        $rendered.closest('.form-group').addClass('has-selection')
        $rendered
          .closest('.form-group')
          .find('.filter-item-icon')
          .append(`<span class="selected-amount">${data.length}</span>`)
      }
      $rendered.prop('title', formatted)
    }

    return adapter
  }
)

$.fn.select2.amd.define(
  'MultipleCustomDropdownAdapter',
  [
    'select2/utils',
    'select2/dropdown',
    'select2/dropdown/attachBody',
    'select2/dropdown/attachContainer',
    'select2/results',
    'select2/dropdown/search',
  ],
  function (Utils, Dropdown, AttachBody, AttachContainer, Results, Search) {
    // Decorate Dropdown with Search functionalities
    const dropdownWithSearch = Utils.Decorate(Dropdown, Search)
    dropdownWithSearch.prototype.render = function () {
      // Copy and modify default search render method
      const $rendered = Dropdown.prototype.render.call(this)
      // Add ability for a placeholder in the search box

      const showSearch = this.options.get('showSearch') === false ? this.options.get('showSearch') : true
      const placeholder = this.options.get('placeholderForSearch') || ''
      const $search = $(`
      <span class="select2-search select2-search--dropdown ${!showSearch ? 'hidden' : ''}">
        <input class="select2-search__field" placeholder="${placeholder}" type="search"
         tabindex="-1" autocomplete="off" autocorrect="off" autocapitalize="off"
         spellcheck="false" role="textbox" />
      </span>
    `)

      this.$searchContainer = $search
      this.$search = $search.find('input')

      $rendered.prepend($search)

      if (this.$element.attr('multiple') && this.$element.data('select-all')) {
        const $selectAll = $(`<span class="select-all-option">${Translator.trans('filter_by.select-all')}</span>`)

        $selectAll.on('click', () => {
          const select2data = this.$element.data('select2')
          const $results = $rendered.find('.select2-results__option[aria-selected=false]')

          if ($results.length) {
            select2data.options.options.data.forEach((d) => this.trigger('select', { data: d }))
          } else {
            this.$element.val(null)
            this.$element.trigger('change')
            this.trigger('close')
          }
        })

        $rendered.append($selectAll)
      }

      if (this.$element.data('sorting')) {
        const $sortingBox = $(`<div class="sorting-box"></div>`)
        const $sortingOptions = $(
          '<span data-sorting="asc" class="active">ASCENDING</span><span data-sorting="desc">DESCENDING</span>'
        )
        $sortingBox.append($sortingOptions)

        $sortingBox.find('span').on('click', (e) => {
          const $this = $(e.currentTarget)
          $sortingBox.find('span').toggleClass('active', false)
          $this.toggleClass('active', true)
          this.$element.attr('data-sorting', $this.data('sorting'))
        })

        $rendered.append($sortingBox)
      }

      return $rendered
    }

    // Decorate the dropdown+search with necessary containers
    let adapter = Utils.Decorate(dropdownWithSearch, AttachContainer)
    adapter = Utils.Decorate(adapter, AttachBody)

    return adapter
  }
)

$.fn.select2.amd.define(
  'CustomDropdownSearchAdapter',
  ['MultipleCustomDropdownAdapter', 'select2/utils', 'select2/dropdown/closeOnSelect'],
  function (MultipleCustomDropdownAdapter, Utils, CloseOnSelect) {
    const adapter = Utils.Decorate(MultipleCustomDropdownAdapter, CloseOnSelect)
    return adapter
  }
)
